import React from 'react';
import PropTypes from 'prop-types';
import TopNewsSection from './Featured/TopNewsSection';

const ArticleLayout = ({
  featuredVariables,
  categorySection,
  showCreatedAt,
  expandedSections,
}) => (
  <TopNewsSection
    variables={featuredVariables}
    showCreatedAt={showCreatedAt}
    expandedSections={expandedSections}
    categorySection={categorySection}
  />
);

ArticleLayout.propTypes = {
  featuredVariables: PropTypes.shape({}),
  categorySection: PropTypes.node,
  showCreatedAt: PropTypes.bool,
  expandedSections: PropTypes.bool,
};

ArticleLayout.defaultProps = {
  featuredVariables: null,
  categorySection: null,
  showCreatedAt: false,
  expandedSections: false,
};

export default ArticleLayout;
